import PropTypes from 'prop-types';
import ProductTags from './ProductTags';

function ProductHeader({
  name, discountedPrice, price, tags, isTitle,
}) {
  return (
    <header className="product__header">
      {isTitle ? (
        <h1 className="product__title">{name}</h1>
      ) : (
        <>
          <span className="product__name">{name}</span>
          <ProductTags tags={tags} discountedPrice={discountedPrice} price={price} />
        </>
      )}
    </header>
  );
}

export default ProductHeader;

ProductHeader.defaultProps = {
  isTitle: false,
  name: '',
  discountedPrice: 0,
  price: 0,
  tags: [],
};

ProductHeader.propTypes = {
  isTitle: PropTypes.bool,
  name: PropTypes.string,
  discountedPrice: PropTypes.number,
  price: PropTypes.number,
  tags: PropTypes.array,
};
