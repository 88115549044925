import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonGroup from '../../components/ButtonGroup';
import CommonHeader from '../../components/CommonHeader';
import IconRender from '../../components/IconRender';
import SearchBar from '../../components/SearchBar/SearchBar';

function Header(props) {
  const {
    selectedCategoryType, categoryTypes, onChangeCategoryType, children, onSearch, isLoading, menu,
  } = props;

  const location = useSelector((state) => state.location);
  const currentLocation = location.selectedStore;
  const navigation = useNavigate();
  const { storeID: legacyID } = useParams();
  const handleShowStores = () => navigation('/stores');
  const handleSelectOrderMethod = () => navigation('/SelectOrderMethod');
  const handleCart = () => navigation(`/store/${legacyID}/cart`);
  const handleCategoryChange = (_, categoryType) => {
    const foundCategoryType = categoryTypes.find((ct) => ct.categoryType === categoryType);
    onChangeCategoryType(foundCategoryType);
  };

  return (
    <CommonHeader
      onGoToCart={handleCart}
      onGoToSelectOrderMethod={handleSelectOrderMethod}
      onGoToStores={handleShowStores}
      currentLocation={currentLocation}
      isLoading={isLoading}
      menu={menu}
      endComponent={(
        <>
          {categoryTypes.length > 1 && (
            <ButtonGroup
              color="primary-gray"
              className="font-medium"
              size="sm"
              selectedProps={{
                color: 'primary',
              }}
              value={selectedCategoryType}
              onClick={handleCategoryChange}
              options={categoryTypes.map((categoryType) => ({
                value: categoryType.categoryType,
                label: categoryType.categoryType,
                icon: <IconRender icon={categoryType.icon} className="w-3 h-3" />,
              }))}
            />
          )}
          <div className="min-w-[320px] max-w-xs header__search-bar">
            {children || <SearchBar onChange={onSearch} />}
          </div>
        </>
      )}
    />
  );
}

Header.propTypes = {
  selectedCategoryType: PropTypes.string,
  categoryTypes: PropTypes.array,
  onChangeCategoryType: PropTypes.func,
  children: PropTypes.node,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

Header.defaultProps = {
  selectedCategoryType: undefined,
  categoryTypes: [],
  onChangeCategoryType: () => null,
  children: null,
  onSearch: () => null,
};

export default Header;
