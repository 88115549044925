import PropTypes from 'prop-types';

function TextArea({
  className,
  size,
  value,
  placeholder,
  onChange,
  fullWidth,
  rows,
  fieldState,
  ...args
}) {
  const { invalid, isDirty } = fieldState;

  return (
    <textarea
      className="textarea__input"
      data-size={size}
      data-full={fullWidth}
      data-invalid={invalid && isDirty}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      rows={rows}
      {...args}
    />
  );
}

TextArea.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  rows: PropTypes.number,
  fieldState: PropTypes.any,
};
TextArea.defaultProps = {
  className: '',
  size: 'md',
  value: '',
  placeholder: '',
  onChange: () => null,
  fullWidth: false,
  rows: 3,
  fieldState: {},
};

export default TextArea;
