import PropTypes from 'prop-types';
import { useCallback } from 'react';

import PlusIcon from 'src/components/icons/PlusIcon';
import MinusIcon from '../icons/MinusIcon';

function InputCounterNumber({
  value,
  min = 0,
  max = Infinity,
  step = 1,
  onChange,
  onConfirmOptions,
  readOnly = false,
  disabled = false,
  maxDisabled = false,
}) {
  const validateNumber = useCallback(
    (num) => {
      const parsedNum = Number(num);

      return !Number.isNaN(parsedNum) ? parsedNum : min;
    },
    [min],
  );

  const handleSubtract = useCallback(() => {
    if (readOnly) {
      return;
    }

    const currentValue = validateNumber(value);
    const newVal = Math.max(currentValue - step, min);

    if (!Number.isFinite(newVal)) {
      return;
    }
    onChange(newVal);

    if (newVal === min) {
      onConfirmOptions(true);
    }
  }, [readOnly, value, step, min, onChange, onConfirmOptions, validateNumber]);

  const handleAdd = useCallback(() => {
    if (readOnly) {
      return;
    }

    const currentValue = validateNumber(value);
    const newVal = Math.min(currentValue + step, max);

    if (!Number.isFinite(newVal)) {
      return;
    }

    onChange(newVal);
    onConfirmOptions(false);
  }, [readOnly, value, step, max, onChange, onConfirmOptions, validateNumber]);

  const displayValue = validateNumber(value);

  return (
    <div className="counter__container">
      <button
        type="button"
        onClick={handleSubtract}
        disabled={disabled || value === min}
        className="button__subtract"
        title="Quita una unidad"
      >
        <MinusIcon />
      </button>
      <span className="counter__value" disabled={disabled || (value === min && maxDisabled)}>
        {displayValue}
      </span>
      <button
        type="button"
        onClick={handleAdd}
        disabled={disabled || value === max || maxDisabled}
        className="button__add"
        title="Añade una unidad"
      >
        <PlusIcon />
      </button>
    </div>
  );
}

InputCounterNumber.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  onConfirmOptions: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  maxDisabled: PropTypes.bool,
};
InputCounterNumber.defaultProps = {
  value: 0,
  min: 0,
  max: 999,
  step: 1,
  onChange: () => null,
  onConfirmOptions: () => true,
  readOnly: false,
  disabled: false,
  maxDisabled: false,
};

export default InputCounterNumber;
