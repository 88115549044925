import { AnimatePresence } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import Recover from 'src/screens/Login/Recover';
import Register from 'src/screens/Login/Register';
import Toast from './components/Toast';
import AlertProvider from './contexts/Alert.context';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import { setToken } from './redux/actions/fcm';
import AddressInput from './screens/Delivery/AddressInput';
import AddressMap from './screens/Delivery/AddressMap';
import ConfirmationSuccess from './screens/Delivery/ConfirmationSuccess';
import DeliveryDetails from './screens/Delivery/DeliveryDetails';
import DeliveryError from './screens/Delivery/DeliveryError';
import Finished from './screens/Delivery/Finished';
import MyAddress from './screens/Delivery/MyAdress';
import Home from './screens/Home';
import LoginWrapper from './screens/Login';
import Login from './screens/Login/Login';
import OrderRating from './screens/OrderRating';
import OrderStatus from './screens/OrderStatus';
import ProductDetail from './screens/ProductDetail';
import SearchOrder from './screens/SearchOrder';
import Store from './screens/Store';
import Cart from './screens/Store/Cart';
import Stores from './screens/Stores';
import { initAmplitude } from './services/amplitude';
import FCMService from './services/fcm';
import useChannelAvailability from './hooks/useChannelAvailability';
import { UNAVAILABLE_CHANNELS } from './constants';
import useHeaderObserver from './hooks/useHeaderObserver';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { sendPageEvent } = useGoogleAnalytics();
  sendPageEvent();
  FCMService.getFCMToken().then((data) => {
    dispatch(setToken(data));
  });
  initAmplitude();

  useChannelAvailability(UNAVAILABLE_CHANNELS);

  useHeaderObserver();

  return (
    <>
      <Toast />
      <AlertProvider>
        <AnimatePresence initial={false} mode="sync">
          <LoginWrapper>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Home />} key="start" />
              <Route path="/login" element={<Login />} key="login" />
              <Route path="/recuperar" element={<Recover />} key="recuperar" />
              <Route path="/registro" element={<Register />} key="registro" />
              <Route path="/stores" element={<Stores />} key="stores" />
              <Route path="/store/:storeID" element={<Store />} key="store">
                <Route path="cart" element={<Cart />} key="cart" />
              </Route>
              <Route path="/store/:storeID/product/:productID" element={<ProductDetail />} key="productDetail" />
              <Route path="/order/search" element={<SearchOrder />} key="searchOrder" />
              <Route path="/order/:orderID/status" element={<OrderStatus />} key="orderStatus" />
              <Route path="/order/:orderID/rating" element={<OrderRating />} key="orderRating" />
              {/* Deprecated */}
              <Route path="/SelectOrderMethod" element={<Home />} key="selectordermethod" />
              <Route path="/AddressInput" element={<AddressInput />} key="addressInput" />
              <Route path="/MyAddress" element={<MyAddress />} key="MyAddress" />
              <Route path="/DeliveryDetails" element={<DeliveryDetails />} key="DeliveryDetails" />
              <Route path="/DeliveryError" element={<DeliveryError />} key="DeliveryError" />
              <Route path="/Finished" element={<Finished />} key="Finished" />
              <Route path="/AddressMap" element={<AddressMap />} key="addressMap" />
              <Route path="/orderStatus" element={<OrderStatus />} key="orderStatus" />
              <Route path="/ConfirmationSuccess" element={<ConfirmationSuccess />} key="ConfirmationSuccess" />
            </Routes>
          </LoginWrapper>
        </AnimatePresence>
      </AlertProvider>
    </>
  );
}

export default App;
