import {
  useEffect, useMemo, useState,
} from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import mapLegacyStore from 'src/helpers/migrationRedirect';
import processCategories from 'src/utils/categoriesUtils';
import ContainerWithMenu from '../../components/ContainerWithMenu';
import IllustratedMessage from '../../components/IllustratedMessage';
import ItemsGroup from '../../components/ItemsGroup';
import ProductItem from '../../components/ProductItem';
import Skeleton from '../../components/Skeleton';
import { useScheduler } from '../../contexts/Scheduler.context';
import { useShoppingCart } from '../../hooks/actions/useShoppingCart';
import { useStore } from '../../hooks/actions/useStore';
import { useMenu } from '../../hooks/services/useMenu';
import { useAmplitude } from '../../hooks/useAmplitude';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import Header from '../components/Header';
import StoreNotAvailable from '../components/StoreNotAvailable';

import '../../styles/Menu.css';

function Store() {
  const {
    selectedCategoryType,
    sessionCategoryType,
    availableCategories,
    notAvailableSchedule,
    handleCategoryType,
  } = useScheduler();
  const { selectedStore, changeStore } = useStore();
  const { items, clearCart } = useShoppingCart();
  const { storeID: legacyID } = useParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const storePageEvents = useAmplitude().useStorePageEvents();
  const { trackSelectStorePickup, trackSelectItemMenu } = storePageEvents;

  const storeID = mapLegacyStore(legacyID);

  const { menu, isLoading, isError } = useMenu({
    searchValue,
    selectedCategoryType,
    storeID,
  });
  useGoogleAnalytics('event', 'search', { searchValue });

  const menuCategories = useMemo(
    () => processCategories(menu.menuCategories)
      ?.filter((menuCategory) => menuCategory.show)
      .map((menuCategory) => ({
        ...menuCategory,
        products: menuCategory.products.map((product) => ({
          ...product,
          onCart: items.reduce((total, item) => total + (item.id === product.id ? item.count : 0), 0),
        })),
      })),
    [menu],
  );
  const notFoundProductsCondition = !isLoading && !!menuCategories && menuCategories.length === 0;

  const handleFilterProducts = (filterValue) => setSearchValue(filterValue);
  const handleOpenProduct = (productId) => {
    trackSelectItemMenu(productId);
    navigate(`product/${productId}`);
  };

  useEffect(() => {
    if (!menu.store) {
      return;
    }

    if (selectedStore?.storeId && menu.store.storeId !== selectedStore?.storeId) {
      clearCart();
    }

    if (!sessionCategoryType) {
      clearCart();
    }

    changeStore(menu.store);
    trackSelectStorePickup(menu.store.storeId);
  }, [menu.store, items.length, sessionCategoryType]);

  if (notAvailableSchedule) {
    return <StoreNotAvailable {...selectedStore?.currentSchedule} />;
  }

  return (
    <>
      <Header
        isLoading={isLoading ?? false}
        categoryTypes={availableCategories}
        selectedCategoryType={selectedCategoryType}
        onChangeCategoryType={handleCategoryType}
        onSearch={handleFilterProducts}
        value={searchValue}
        menu={menuCategories?.map((productGroup) => ({
          id: `group-${productGroup.id}`,
          label: productGroup.name,
        })) ?? []}
      />
      <ContainerWithMenu
        offsetTop={128}
        menu={menuCategories?.map((productGroup) => ({
          id: `group-${productGroup.id}`,
          label: productGroup.name,
        }))}
      >
        {isLoading && (
          [{ count: 4 }, { count: 5 }].map((group) => (
            <ItemsGroup
              title={<Skeleton className="h-8 w-1/2 md:w-1/4" />}
              key={crypto.randomUUID()}
              showImg
            >
              {Array(group.count)
                .fill()
                .map(() => (
                  <ProductItem
                    key={crypto.randomUUID()}
                    isLoading={isLoading}
                    id={crypto.randomUUID()}
                  />
                ))}
            </ItemsGroup>
          ))
        )}
        {notFoundProductsCondition || isError ? (
          <IllustratedMessage
            title="Lo sentimos"
            description={
                isError
                  ? 'Ocurrió un error cargando los datos. Inténtalo más tarde'
                  : 'No encontramos lo que buscas'
              }
          />
        ) : (
          menuCategories?.map((currentProductGroup) => (
            <ItemsGroup
              id={`group-${currentProductGroup.id}`}
              key={currentProductGroup.id}
              title={currentProductGroup.name}
              offsetTop={188}
              showImg={currentProductGroup.showImage}
            >
              {currentProductGroup.products.map((product) => (
                <ProductItem
                  key={`product-${product.id}-${crypto.randomUUID()}`}
                  {...product}
                  imageUrl={product.shortcutImage}
                  onClick={handleOpenProduct}
                />
              ))}
            </ItemsGroup>
          ))
        )}
      </ContainerWithMenu>
      <Outlet />
    </>
  );
}

export default Store;
