/**
 * Clase utilitaria para manejar el scroll de categorías
 */
export default class CategoryScroller {
  /**
   * Desplaza la ventana hacia la categoría indicada
   * @param {string} id - Identificador de la categoría
   */
  static scrollToCategory({ id }) {
    const element = document.getElementById(id);
    const header = document.getElementById('header');

    if (element && header) {
      const headerHeight = header.offsetHeight || 0;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  /**
   * Maneja el scroll automático de categorías
   * @param {HTMLElement} link - Elemento activo del menú
   * @param {NodeList} $navLinks - Lista de enlaces de navegación
   * @param {HTMLElement} $categoryList - Contenedor de la lista de categorías
   * @param {boolean} scrollingUp - Indica si el scroll es hacia arriba
   */
  static handleCategoryScroll({
    link, $navLinks, $categoryList, scrollingUp,
  }) {
    const containerWidth = $categoryList.offsetWidth;
    const containerScrollWidth = $categoryList.scrollWidth;
    const containerScrollLeft = $categoryList.scrollLeft;
    const linkRect = link.getBoundingClientRect();
    const containerRect = $categoryList.getBoundingClientRect();
    const isLastItem = link === $navLinks[$navLinks.length - 1];

    if (isLastItem) {
      $categoryList.scrollTo({
        left: containerScrollWidth - containerWidth,
        behavior: 'smooth',
      });
      return;
    }

    const linkCenter = linkRect.left + linkRect.width / 2;
    const containerCenter = containerRect.left + containerRect.width / 2;
    const isBeyondCenter = linkCenter > containerCenter;
    const isBeforeCenter = linkCenter < containerCenter;

    if ((isBeyondCenter && !scrollingUp) || (isBeforeCenter && scrollingUp)) {
      $categoryList.scrollTo({
        left: containerScrollLeft + (linkCenter - containerCenter),
        behavior: 'smooth',
      });
    }
  }
}
