import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import CategoryScroller from 'src/utils/CategoryScroller';

/**
 * Obtiene los elementos del DOM necesarios para la observación del encabezado
 * @returns {{
 *   $header: HTMLElement|null - Elemento del encabezado,
 *   $sections: NodeList - Lista de secciones,
 *   $navLinks: NodeList - Lista de enlaces de navegación,
 *   $categoryList: HTMLElement|null - Lista de categorías
 * }}
 */
const getHeaderElements = () => ({
  $header: document.querySelector('.header'),
  $sections: document.querySelectorAll('.wrapper'),
  $navLinks: document.querySelectorAll('.menu__item:not(.menu__item--skeleton)'),
  $categoryList: document.querySelector('.categoryName__list'),
});

/**
 * Actualiza el padding de desplazamiento basado en la altura de la navegación
 * @param {Object} params
 * @param {number} params.navigationHeight - Altura del encabezado de navegación
 */
const updateScrollPadding = ({ navigationHeight }) => {
  document.documentElement.style.setProperty('--scroll-padding', `${navigationHeight}px`);
};

/**
 * Determina si el usuario está desplazándose hacia arriba
 * @param {Object} params
 * @param {number} params.currentScroll - Posición actual del scroll
 * @param {number} params.lastScrollTop - Última posición conocida del scroll
 * @returns {boolean} true si está subiendo, false si está bajando
 */
const isScrollingUp = ({ currentScroll, lastScrollTop }) => currentScroll < lastScrollTop;

/**
 * Hook personalizado que observa el comportamiento del encabezado durante el scroll
 * Gestiona el seguimiento de la posición del scroll y actualiza los estados de navegación
 * basándose en las secciones visibles en el viewport
 * @returns {void}
 */
function useHeaderObserver() {
  const lastScrollTop = useRef(0);
  const isInitialized = useRef(false);
  const { handleCategoryScroll } = CategoryScroller;
  const location = useLocation();

  const updateHeaderPadding = () => {
    const { $header } = getHeaderElements();
    if ($header) {
      const navigationHeight = $header.offsetHeight || 0;
      updateScrollPadding({ navigationHeight });
    }
  };

  useEffect(() => {
    updateHeaderPadding();
  }, [location.pathname]);

  const handleScrollActive = () => {
    const {
      $header, $sections, $navLinks, $categoryList,
    } = getHeaderElements();

    if (!$header || !$sections.length || !$navLinks.length || !$categoryList) {
      return;
    }

    const navigationHeight = $header.offsetHeight || 0;
    updateScrollPadding({ navigationHeight });

    const { scrollY } = window;
    const scrollingUp = isScrollingUp({ currentScroll: scrollY, lastScrollTop: lastScrollTop.current });
    lastScrollTop.current = scrollY;

    let activeFound = false;
    let currentActiveId = null;

    $sections.forEach(($section) => {
      const { offsetHeight, offsetTop } = $section;
      const sectionTop = offsetTop - navigationHeight;
      const $subtitle = $section.querySelector('.category__subtitle');
      const id = $subtitle?.id;

      if (!id) {
        return;
      }

      const sectionBottom = sectionTop + offsetHeight;
      const isInViewport = (sectionTop <= scrollY + 1 && sectionBottom >= scrollY + 1)
        || (scrollY + 1 >= sectionTop && scrollY + 1 < sectionBottom)
        || (scrollY > 0 && offsetTop < scrollY + window.innerHeight && offsetTop > scrollY + 1);

      if (isInViewport && !activeFound) {
        activeFound = true;
        currentActiveId = id;
      }
    });

    $navLinks.forEach((link) => {
      const linkId = link.getAttribute('data-category-link');
      if (linkId === currentActiveId) {
        $navLinks.forEach((l) => l.blur());

        link.classList.add('active');
        handleCategoryScroll({
          link,
          $navLinks,
          $categoryList,
          scrollingUp,
        });
      } else {
        link.classList.remove('active');
      }
    });

    if (!activeFound && $navLinks[0] && !isInitialized.current) {
      $navLinks[0].classList.add('active');
      isInitialized.current = true;
    }
  };

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && !document.querySelector('.menu__item--skeleton')) {
          handleScrollActive();
          observer.disconnect();
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    window.addEventListener('scroll', handleScrollActive);
    window.addEventListener('load', handleScrollActive);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScrollActive);
      window.removeEventListener('load', handleScrollActive);
    };
  }, []);

  return null;
}

export default useHeaderObserver;
