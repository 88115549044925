import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import ChevronDown from '../icons/ChevronDown';

function Accordion({
  title,
  children,
  accId,
  className,
  description,
  isOptional,
  defaultExpanded = true,
  selectedCount,
  maxAllowed,
}) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const handleToggle = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        handleToggle();
      }
    },
    [handleToggle],
  );

  return (
    <div className={className}>
      <div className="accordion__panel">
        <button
          type="button"
          className="accordion__trigger"
          aria-expanded={isExpanded}
          aria-controls={`accordion-content-${accId}`}
          onClick={handleToggle}
          onKeyDown={handleKeyDown}
        >
          <div className="accordion__header">
            <h2 id={`accordion-header-${accId}`} className="modifier__title">
              {title}
              {isOptional && <span className="font-medium text-[14px] text-baco-primary italic">(Opcional)</span>}
            </h2>
            {description && <span className="accordion__description">{description}</span>}
            {maxAllowed > 0 && (
              <p className="accordion__counter">
                <div className="accordion__counter-wrapper">
                  <span className="accordion__counter-selected">{selectedCount}</span>
                  <span className="accordion__counter-separator">/</span>
                  <span className="accordion__counter-selected">{maxAllowed}</span>
                </div>
                <span className="accordion__counter-text">{maxAllowed > 1 ? ' seleccionados' : ' seleccionado'}</span>
              </p>
            )}
          </div>

          <span className="accordion__icon" aria-hidden="true">
            <ChevronDown />
          </span>
        </button>

        <div
          className={`accordion__content ${isExpanded ? 'expanded' : ''}`}
          role="region"
          aria-labelledby={`accordion-header-${accId}`}
          aria-hidden={!isExpanded}
          id={`accordion-content-${accId}`}
        >
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  accId: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  isOptional: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  selectedCount: PropTypes.number,
  maxAllowed: PropTypes.number,
};

Accordion.defaultProps = {
  className: '',
  description: '',
  isOptional: false,
  defaultExpanded: true,
  selectedCount: 0,
  maxAllowed: 1,
};
