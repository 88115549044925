import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderWithButton from '../../../components/HeaderWithButton';
import { LogoIcon } from '../../../components/icons';
import Stepper from '../../../components/Stepper';
import { LoginScreens, useLogin } from '../../../contexts/Login.context';
import { useSignUp } from '../../../hooks/services/useSignUp';
import AnimatedStepper from '../../../templates/AnimatedStepper';
import Step1 from './FormCreateAccountFirstStep';
import Step2 from './FormCreateAccountSecondStep';

function CreateAccount() {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    cellphone: {
      number: '',
      area: '57',
    },
    email: '',
    password: '',
    passwordConfirmation: '',
    authorize: false,
  });
  const { handleChangeScreen } = useLogin();
  const { signUp, isLoading, error } = useSignUp();
  const navigate = useNavigate();

  const handleBack = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    } else {
      navigate('/login');
      handleChangeScreen(LoginScreens.LOGIN, 'right');
    }
  };

  const onSubmit = (_data) => {
    const newData = { ...data, ..._data };

    if (step === 0) {
      setData(newData);
      setStep(1);
    } else {
      signUp({
        email: newData.email,
        password: newData.password,
        first_name: newData.firstName,
        last_name: newData.lastName,
        phone: {
          number: newData.cellphone.number.toString(),
          country_code: newData.cellphone.area,
        },
      });
    }
  };

  const Steps = useMemo(() => [
    <Step1 data={data} onSubmit={onSubmit} />,
    <Step2 data={data} onSubmit={onSubmit} isLoading={isLoading} error={error} />,
  ], [onSubmit, data]);

  return (
    <div className="w-full max-w-6xl mx-auto h-full flex flex-col overflow-hidden">
      <HeaderWithButton
        buttonVariant="normal"
        onClick={handleBack}
        centerAdornment={<Stepper step={2} stepActive={step + 1} />}
        endAdornment={
          <LogoIcon className={clsx('h-16 w-8 sm:h-14 sm:w-7 md:h-10 md:w-10', 'fill-baco-primary  mr-5 md:mr-24')} />
        }
      />

      <AnimatedStepper
        current={Steps[step]}
        previous={Steps[step - 1 < 0 ? 1 : 0]}
        direction={step === 0 ? 'right' : 'left'}
        duration={0.5}
        className="overflow-y-hidden"
      />
    </div>
  );
}

export default CreateAccount;
