import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import Accordion from '../Accordion';
import ModifierItem from '../ModifierItem';

function getDescription(selectType, minAllowed, maxAllowed) {
  if (selectType === 'radioBtn') {
    return 'Escoge una opción';
  }

  if (minAllowed === 0) {
    return `Escoge máximo ${maxAllowed} opciones`;
  }

  if (minAllowed === maxAllowed) {
    return `Escoge ${minAllowed} opciones`;
  }

  return `Escoge entre ${minAllowed} y ${maxAllowed} opciones`;
}

function ModifierGroup({
  name, modifiers, selectType, index, minAllowed, maxAllowed, disables, disabled,
}) {
  const { max } = disables;

  const values = useWatch({
    name: `modifierGroups.${index}.modifiers`,
  });

  const selectedCount = useMemo(() => {
    if (!values) {
      return 0;
    }

    if (selectType === 'radioBtn') {
      const hasSelection = Object.values(values).some((value) => value?.value || typeof value === 'string');

      return hasSelection ? 1 : 0;
    }

    return Object.values(values).reduce((count, value) => {
      if (value === undefined || value === null) {
        return count;
      }

      if (typeof value === 'boolean') {
        return count + (value ? 1 : 0);
      }

      if (typeof value === 'number') {
        return count + (Number.isNaN(value) ? 0 : value);
      }

      if (typeof value === 'object' && value?.value) {
        const parsed = parseInt(value.value, 10);

        return count + (Number.isNaN(parsed) ? 0 : parsed);
      }

      return count;
    }, 0);
  }, [values, selectType]);

  const getInputName = ({ i }) => {
    if (selectType === 'radioBtn') {
      return `modifierGroups.${index}.modifiers.0.value`;
    }

    return `modifierGroups.${index}.modifiers.${i}.value`;
  };
  return (
    <Accordion
      className="modifier__wrapper"
      accId={`modifier-group-${index}-${name}`}
      title={name}
      isOptional={minAllowed === 0}
      description={getDescription(selectType, minAllowed, maxAllowed)}
      selectedCount={selectedCount}
      maxAllowed={maxAllowed}
    >
      {modifiers.map(({ imageUrl, ...modifier }, i) => (
        <Fragment key={modifier.id}>
          <ModifierItem
            disabled={disabled}
            {...modifier}
            id={modifier.id}
            selectType={selectType}
            maxDisabled={max}
            inputName={getInputName({ i })}
            value={selectType === 'radioBtn' ? modifier.name : undefined}
            className=""
          />
        </Fragment>
      ))}
    </Accordion>
  );
}

ModifierGroup.propTypes = {
  name: PropTypes.string.isRequired,
  modifiers: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  disables: PropTypes.shape({
    min: PropTypes.bool,
    max: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  minAllowed: PropTypes.number,
  maxAllowed: PropTypes.number,
  selectType: PropTypes.oneOf(['checkBoxes', 'counters', 'radioBtn']),
  className: PropTypes.string,
};
ModifierGroup.defaultProps = {
  selectType: 'counters',
  disables: {},
  disabled: false,
  minAllowed: 0,
  maxAllowed: 1,
  className: '',
};

export default ModifierGroup;
