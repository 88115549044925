import PropTypes from 'prop-types';

function Logo({ className, size }) {
  return (
    <svg
      className={className}
      fill="currentColor"
      height={size}
      viewBox="0 0 21 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.757 29.3727C20.757 30.8235 19.5412 31.9999 18.0417 31.9999C16.5421 31.9999 15.3269 30.8235
        15.3269 29.3727C15.3269 27.9219 16.5421 26.7461 18.0417 26.7461C19.5412 26.7461 20.757 27.9219 20.757 29.3727Z"
      />
      <path
        d="M6.55557 25.9361C7.43573 25.9361 8.11269 25.4125 8.11269 24.3972C8.11269 23.4145 7.43573 22.8577 6.55557
        22.8577H6.35236C5.81068 22.8577 5.60748 23.087 5.60748 23.6105V25.0195C5.60748 25.5436 5.81068 25.9361
        6.35236 25.9361H6.55557ZM6.5216 17.9445C7.40177 17.9445 8.07873 17.4204 8.07873 16.405C8.07873 15.4224
        7.40177 14.8655 6.5216 14.8655H6.35236C5.81068 14.8655 5.60748 15.0954 5.60748 15.6189V17.1911C5.60748
        17.7152 5.81068 17.9445 6.35236 17.9445H6.5216ZM5.70879 8.9375C10.7532 8.9375 13.8674 11.2631 13.8674
        16.1097C13.8674 17.7141 13.4276 18.9898 12.8888 20.0724C12.8209 20.2034 12.7536 20.3672 12.7536 20.4982C
        12.7618 20.6395 12.8085 20.776 12.8888 20.8935C13.5658 21.9101 13.8674 23.1209 13.8674 24.6603C
        13.8674 29.442 11.2609 31.8658 6.58896 31.8658H0.868178C0.326494 31.8658 0.123291 31.6693 0.123291
        31.1452V9.65812C0.123291 9.13404 0.326494 8.9375 0.868178 8.9375H5.70879Z"
      />
      <path
        d="M13.5205 5.77896H0.312245C0.271575 5.77926 0.231242 5.77167 0.193552 5.75668C0.155861 5.74168 0.12155 5.71958
        0.0925781 5.69157C0.0636059 5.66357 0.0405406 5.63024 0.0246979 5.59349C0.00885514 5.55674 0.000545399 5.51727
        0.000244141 5.47737V0.302148C0.000470072 0.262201 0.00872367 0.222678 0.0245327 0.185864C0.0403418 0.14905
        0.0633964 0.11565 0.0923761 0.0875867C0.121356 0.0595234 0.155692 0.0373462 0.19342 0.0223166C0.231148
        0.00728702 0.271527 -0.000289185 0.312245 8.44036e-06H13.5205C13.6026 -0.000442571 13.6817 0.0311068
        13.7402 0.0877521C13.7987 0.144397 13.8319 0.221516 13.8325 0.302148V5.47737C13.8322 5.51727 13.8238 5.55674
        13.808 5.59349C13.7922 5.63024 13.7691 5.66357 13.7401 5.69157C13.7112 5.71958 13.6768 5.74168 13.6391
        5.75668C13.6015 5.77167 13.5611 5.77926 13.5205 5.77896Z"
      />
    </svg>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

Logo.defaultProps = {
  className: '',
  size: 24,
};

export default Logo;
