import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

const useScrollHandler = () => {
  const { pathname } = useLocation();
  const navigationType = useNavigationType();

  const scrollToPosition = (position) => {
    window.scrollTo({
      top: position,
      behavior: 'instant',
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  };

  const savePosition = () => {
    sessionStorage.setItem(`scroll-${pathname}`, window.scrollY.toString());
  };

  const getPosition = () => parseInt(sessionStorage.getItem(`scroll-${pathname}`), 10) || 0;

  useEffect(() => {
    const isProductDetail = pathname.includes('/product/');

    if (isProductDetail) {
      scrollToTop();
      return;
    }

    if (navigationType === 'PUSH') {
      savePosition();
      scrollToTop();
    }

    if (navigationType === 'POP') {
      const savedPosition = getPosition();
      scrollToPosition(savedPosition);
    }

    return () => {
      savePosition();
    };
  }, [pathname, navigationType]);
};

export default useScrollHandler;
