import PropTypes from 'prop-types';

function ProductBadge({ isAvailable }) {
  return (
    <div className="product__badge" data-show-badge={!isAvailable}>
      {!isAvailable && <span className="product__badge--available">No disponible</span>}
    </div>
  );
}

export default ProductBadge;

ProductBadge.defaultProps = {
  isAvailable: true,
};

ProductBadge.propTypes = {
  isAvailable: PropTypes.bool,
};
