import PropTypes from 'prop-types';
import formatCurrency from 'src/utils/currency';
import ProductTags from './ProductTags';

function ProductPrice({ price, discountedPrice, tags }) {
  return (
    <div className="product__price">
      {discountedPrice && discountedPrice < price ? (
        <span className="product__price--discounted">{formatCurrency(price)}</span>
      ) : null}

      <span className="product__price--original">
        {formatCurrency(discountedPrice && discountedPrice < price ? discountedPrice : price)}
      </span>

      {tags?.length > 0 && discountedPrice && discountedPrice < price ? (
        <ProductTags discountedPrice={discountedPrice} price={price} tags={tags} />
      ) : null}
    </div>
  );
}

export default ProductPrice;

ProductPrice.defaultProps = {
  tags: [],
  discountedPrice: null,
  price: 0,
};

ProductPrice.propTypes = {
  tags: PropTypes.array,
  price: PropTypes.number,
  discountedPrice: PropTypes.number,
};
