/* eslint-disable camelcase */
import { ecomBe, oauth } from '../helpers/helperAxios';

export const getUserAddresses = async () => {
  try {
    const response = await ecomBe.get('v1/user/addresses', {
      cache: false,
    });
    return response;
  } catch (err) {
    if (err.response.status === 401) {
      return [];
    }

    throw err;
  }
};

export const addUserAddress = (address) => ecomBe.post('v1/user/address', address);

export const deleteUserAddress = (addressId) => ecomBe.delete(`v1/user/address/${addressId}`);

export const getLocations = async () => {
  const response = await ecomBe.get('v1/locations');
  return response
    .filter((store) => !store.is_order_in_table_location)
    .sort((store) => {
      const available = store.isActive && store.isOpen && store.is_available && store.is_available_pickup;

      return (available ? -1 : 1);
    });
};
export const getMenu = (storeID) => ecomBe.get(`v1/store/${storeID}/menu`);

export const getCompleteMenu = async (storeID, categoryType) => {
  const menu = await ecomBe.get(`v1/store/${storeID}/menu?categoryType=${categoryType}`, {});

  return menu;
};

export const getProductDetail = (storeID, productID) => ecomBe
  .get(`v1/store/${storeID}/product/${productID}`, {
    cache: false,
  });
export const getOrderDetail = (orderID) => ecomBe
  .get(`v1/order/${orderID}`, {
    cache: false,
    headers: {
      'Internal-Order-ID': true,
    },
  });
export const getOrdersByPhone = (phone) => ecomBe.get(`v1/order/phone/${phone}`);
export const rateOrder = (orderId, rating) => ecomBe.post(`v1/order/${orderId}/rating`, { rating });
export const getConfigCategories = () => ecomBe.get('v1/schedule/config');
export const activateCoupon = (code, storeID, type) => ecomBe
  .get(`v1/discounts/coupon/${code}/store/${storeID}?orderType=${type}`);
export const validateAddress = (address) => ecomBe.get(`v1/geolocation/validate/${address}`);
export const getNearestStore = (lat, lng) => ecomBe.get(`v1/geolocation/nearest-store/${lat}/${lng}`);

// Auth relation public endpoints
export const login = ({ email, password }) => ecomBe.post('v1/user/signin', { email, password });
export const register = ({
  email,
  password,
  last_name,
  first_name,
  phone,
}) => ecomBe.post('v1/user/signup', {
  email,
  password,
  last_name,
  first_name,
  phone,
});

// Secure functions, session required

export const getCustomerSession = () => ecomBe.post('v1/secure/customer/session');
export const createdInPOS = (orderID) => ecomBe.get(`v1/secure/order/${orderID}/created-in-pos`, {
  cache: false,
});
export const getUserInfo = () => ecomBe.get('v1/secure/customer', {
  cache: false,
});
export const getPaymentSession = (paymentInformation, orderType) => ecomBe
  .post(`v1/secure/paymentSessionV2?type=${orderType}`, paymentInformation);
export const getEnrollmentPaymentSession = ({ customerSession, type, order_id }) => ecomBe
  .post(`/v1/secure/register/${customerSession}/paymentMethod`, null, {
    params: { type, order_id },
  });
export const generateCheckout = (payment) => ecomBe.post('v1/secure/checkout', payment);

// Oauth functions

export const getInfoEmail = (email) => oauth.post('v1/public/user/taken', { email });
export const requestForgot = ({ email }) => oauth.post('v1/public/user/forgot_password', { email });
export const restoreForgot = ({ password, hash }) => oauth
  .post('v1/email_secure/user/restorePassword', {
    password,
  }, {
    headers: {
      'Content-Type': 'application/json; charset=utf-9',
      authorization: hash,
    },
  });
