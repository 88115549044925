import PropTypes from 'prop-types';

function CheckBox({
  color, size, margin, maxDisabled, checked, ...args
}) {
  return (
    <>
      <input className="checkbox__input" disabled={!checked && maxDisabled} type="checkbox" {...args} />
      <svg className="checkbox__check">
        <polyline points="20 6 9 17 4 12" />
      </svg>
    </>
  );
}

CheckBox.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  margin: PropTypes.oneOf(['none', 'densed']),
  checked: PropTypes.bool,
  maxDisabled: PropTypes.bool,
};

CheckBox.defaultProps = {
  color: 'primary',
  margin: 'none',
  size: 'lg',
  checked: false,
  maxDisabled: false,
};

export default CheckBox;
