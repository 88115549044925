import { useState } from 'react';
import PropTypes from 'prop-types';

function ProductImage({ imageUrl, name }) {
  const [hasError, setHasError] = useState(false);

  return (
    <picture
      style={{
        backgroundImage: `url(${imageUrl ?? ''})`,
      }}
      className="product__picture"
    >
      {imageUrl && !hasError ? (
        <img decoding="async" src={imageUrl} alt={`Imagen de ${name}`} onError={() => setHasError(true)} />
      ) : (
        <div className="overflow-hidden w-full h-full flex flex-col justify-center px-4">
          <img
            className="w-full object-cover bg-white object-center !h-[70%] ![aspect-ratio:auto_16/5]"
            src="/images/logo_bacu.png"
            alt="Logo Bacu"
          />
          <span className="text-baco-primary-700 font-semibold text-center w-full">
            Producto increíble en contrucción
          </span>
        </div>
      )}
    </picture>
  );
}

ProductImage.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
};

ProductImage.defaultProps = {
  imageUrl: null,
  name: '',
};

export default ProductImage;
