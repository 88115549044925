import PropTypes from 'prop-types';

export default function CartIconFill({ className, size }) {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.22404 1.33333C8.59738 0.546667 9.40271 0 10.3334 0H15.6667C16.5974 0 17.4014 0.546667 17.776 1.33333C18.6867 1.34133 19.3974 1.38267 20.032 1.63067C20.7897 1.92703 21.4486 2.43068 21.9334 3.084C22.4227 3.74267 22.6534 4.58667 22.968 5.748L23.9574 9.37733L24.3307 10.4987L24.3627 10.5387C25.564 12.0773 24.992 14.3653 23.848 18.94C23.12 21.8507 22.7574 23.3053 21.672 24.1533C20.5867 25 19.0867 25 16.0867 25H9.91338C6.91338 25 5.41338 25 4.32804 24.1533C3.24271 23.3053 2.87871 21.8507 2.15204 18.94C1.00804 14.3653 0.436043 12.0773 1.63738 10.5387L1.66938 10.4987L2.04271 9.37733L3.03204 5.748C3.34804 4.58667 3.57871 3.74133 4.06671 3.08267C4.55168 2.42984 5.21057 1.92666 5.96804 1.63067C6.60271 1.38267 7.31204 1.34 8.22404 1.33333ZM8.22671 3.33733C7.34404 3.34667 6.98938 3.38 6.69604 3.49467C6.288 3.65424 5.93313 3.92548 5.67204 4.27733C5.43738 4.59333 5.29871 5.03467 4.91204 6.45733L4.15204 9.24267C5.51204 9 7.37071 9 9.91204 9H16.0867C18.6294 9 20.4867 9 21.8467 9.24L21.088 6.45467C20.7014 5.032 20.5627 4.59067 20.328 4.27467C20.067 3.92281 19.7121 3.65157 19.304 3.492C19.0107 3.37733 18.656 3.344 17.7734 3.33467C17.5841 3.73313 17.2857 4.06976 16.9128 4.30552C16.5399 4.54128 16.1079 4.6665 15.6667 4.66667H10.3334C9.89237 4.66662 9.46041 4.54159 9.08755 4.30608C8.71469 4.07057 8.41622 3.73555 8.22671 3.33733Z"
        fill="currentColor"
      />
    </svg>
  );
}

CartIconFill.defaultProps = {
  className: '',
  size: 24,
};

CartIconFill.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};
