import PropTypes from 'prop-types';

function LocationIcon({ className, size }) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8 1.3335C9.5913 1.3335 11.1174 1.96564 12.2426 3.09086C13.3679 4.21607 14 5.7422 14 7.3335C14 9.38283 12.8827 11.0602 11.7053 12.2635C11.1171 12.8582 10.4753 13.3974 9.788 13.8742L9.504 14.0675L9.37067 14.1562L9.11933 14.3162L8.89533 14.4528L8.618 14.6142C8.42976 14.7216 8.21675 14.7781 8 14.7781C7.78325 14.7781 7.57024 14.7216 7.382 14.6142L7.10467 14.4528L6.758 14.2395L6.63 14.1562L6.35667 13.9742C5.61522 13.4725 4.9246 12.8996 4.29467 12.2635C3.11733 11.0595 2 9.38283 2 7.3335C2 5.7422 2.63214 4.21607 3.75736 3.09086C4.88258 1.96564 6.4087 1.3335 8 1.3335ZM8 2.66683C6.76232 2.66683 5.57534 3.15849 4.70017 4.03366C3.825 4.90883 3.33333 6.09582 3.33333 7.3335C3.33333 8.8815 4.18133 10.2402 5.24733 11.3308C5.70569 11.7948 6.20111 12.2207 6.72867 12.6042L7.034 12.8215C7.13267 12.8904 7.22756 12.9544 7.31867 13.0135L7.57867 13.1802L7.80733 13.3195L8 13.4322L8.30333 13.2528L8.548 13.0995C8.67822 13.0168 8.81756 12.9242 8.966 12.8215L9.27133 12.6042C9.79889 12.2207 10.2943 11.7948 10.7527 11.3308C11.8187 10.2408 12.6667 8.8815 12.6667 7.3335C12.6667 6.09582 12.175 4.90883 11.2998 4.03366C10.4247 3.15849 9.23768 2.66683 8 2.66683ZM8 4.66683C8.70724 4.66683 9.38552 4.94778 9.88562 5.44788C10.3857 5.94798 10.6667 6.62625 10.6667 7.3335C10.6667 8.04074 10.3857 8.71902 9.88562 9.21911C9.38552 9.71921 8.70724 10.0002 8 10.0002C7.29276 10.0002 6.61448 9.71921 6.11438 9.21911C5.61428 8.71902 5.33333 8.04074 5.33333 7.3335C5.33333 6.62625 5.61428 5.94798 6.11438 5.44788C6.61448 4.94778 7.29276 4.66683 8 4.66683ZM8 6.00016C7.64638 6.00016 7.30724 6.14064 7.05719 6.39069C6.80714 6.64074 6.66667 6.97987 6.66667 7.3335C6.66667 7.68712 6.80714 8.02626 7.05719 8.27631C7.30724 8.52635 7.64638 8.66683 8 8.66683C8.35362 8.66683 8.69276 8.52635 8.94281 8.27631C9.19286 8.02626 9.33333 7.68712 9.33333 7.3335C9.33333 6.97987 9.19286 6.64074 8.94281 6.39069C8.69276 6.14064 8.35362 6.00016 8 6.00016Z"
        fill="currentColor"
      />
    </svg>
  );
}

LocationIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

LocationIcon.defaultProps = {
  className: '',
  size: 24,
};

export default LocationIcon;
