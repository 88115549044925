import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogin } from 'src/contexts/Login.context';
import useDelivery from 'src/hooks/actions/useDelivery';
import CategoryScroller from 'src/utils/CategoryScroller';
import '../../styles/Header.css';
import Button from '../Button';
import CartButton from '../CartButton';
import Drawer from '../Drawer';
import { ChevronDownIcon, CloseIcon, LogoIcon } from '../icons';
import Skeleton from '../Skeleton';

function CommonHeader({
  currentLocation, onGoToStores, onGoToCart, endComponent, isLoading, menu,
}) {
  const { items = [] } = useSelector((state) => state.cart);
  const total = items.reduce((sum, item) => sum + item.count, 0);
  const { deliveryData } = useDelivery();
  const [openAddressDrawer, setOpenAddressDrawer] = useState(false);
  const navigate = useNavigate();
  const { token } = useLogin();

  const { scrollToCategory } = CategoryScroller;

  return (
    <header className="header" id="header">
      <div className="header__location">
        <a href="/">
          <LogoIcon className="w-5 h-8 fill-baco-primary" alt="logo-bacu" />
        </a>
        <div className="flex flex-col min-w-[130px] items-start">
          {deliveryData.isDelivery ? (
            <>
              <p className="text-xs">Dirección de entrega</p>
              <button type="button" className="text-sm underline" onClick={() => setOpenAddressDrawer(true)}>
                {deliveryData.address}
              </button>
              <Drawer
                open={openAddressDrawer}
                direction="bottom"
                keyboard="true"
                classes={{
                  drawer: 'bg-gray-50 max-w-md !inset-x-0 !m-auto',
                  backdrop: 'z-20',
                  content: 'h-full w-full max-w-4xl mx-auto px-2',
                }}
              >
                <div className="flex flex-col w-full checked:bg-baco-primary">
                  <nav className="flex justify-end py-0">
                    <button type="button" onClick={() => setOpenAddressDrawer(false)}>
                      <CloseIcon className="fill-baco-primary w-7 scale-110" />
                    </button>
                  </nav>
                  <div className="font-bold text-xl py-4">¿Quieres cambiar la dirección?</div>
                  <div className="text-md text-left pb-10">
                    Al cambiar la dirección, tendrás que seleccionar tus productos nuevamente.
                  </div>
                  <Button
                    type="submit"
                    size="lg"
                    fullWidth
                    className="!text-base font-bold"
                    onClick={() => setOpenAddressDrawer(false)}
                  >
                    <span className="mr-2">Mantener dirección actual</span>
                  </Button>
                  <button
                    type="button"
                    className="text-xs underline my-6"
                    onClick={() => {
                      if (token) {
                        navigate('/MyAddress');
                      } else {
                        navigate('/AddressInput');
                      }
                    }}
                  >
                    Cambiar dirección de entrega
                  </button>
                </div>
              </Drawer>
            </>
          ) : (
            <>
              <p className="m-0 leading-none">Recoge en</p>
              <button className="flex m-0 items-center" onClick={onGoToStores} type="button">
                {!currentLocation?.name ? (
                  <Skeleton className="w-[80px] h-4" />
                ) : (
                  <b className="mr-1 max-w-[160px] truncate">{currentLocation?.name}</b>
                )}
                <ChevronDownIcon className="img-fluid bacu-arrow-down" alt="bacu-arrow-down" />
              </button>
            </>
          )}
        </div>
      </div>
      <div className="header__content">{endComponent}</div>
      {isLoading && <Skeleton className="self-center !h-6 !w-6" />}
      {!isLoading && menu.length > 0 && <CartButton total={total} onGoToCart={onGoToCart} />}
      <ul className="categoryName__list" hidden={menu.length === 0}>
        {isLoading
          && Array.apply(0, new Array(22))
            .map(() => crypto.randomUUID())
            .map((key) => (
              <li key={key} className="categoryName__list__wrapper pointer-events-none">
                <div className="menu__item menu__item--skeleton">
                  <Skeleton className="!h-6 !w-[80px]" />
                </div>
              </li>
            ))}
        {menu.length > 0 && (
          menu.map(({ id, label }) => (
            <li key={id} className="categoryName__list__wrapper">
              <button
                type="button"
                aria-current={window.location.hash === `#${id}` ? 'page' : undefined}
                onClick={() => {
                  scrollToCategory({ id });
                  window.location.hash = id;
                }}
                className="menu__item"
                data-category-link={id.toString()}
                data-text={label}
              >
                {label}
              </button>
            </li>
          ))
        )}
      </ul>
    </header>
  );
}

CommonHeader.propTypes = {
  currentLocation: PropTypes.any,
  onGoToStores: PropTypes.func,
  onGoToCart: PropTypes.func,
  endComponent: PropTypes.node,
  isLoading: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

CommonHeader.defaultProps = {
  currentLocation: {},
  onGoToStores: () => null,
  onGoToCart: () => null,
  endComponent: null,
  isLoading: false,
  menu: [],
};

export default CommonHeader;
