import PropTypes from 'prop-types';

function ProductDescription({ description }) {
  return (
    description
    && description.length > 0
    && description !== '-' && <p className="product__description">{description}</p>
  );
}

export default ProductDescription;

ProductDescription.defaultProps = {
  description: '',
};

ProductDescription.propTypes = {
  description: PropTypes.string,
};
