import { motion, MotionConfig } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBack from 'src/components/icons/ArrowBack';
import ProductImage from 'src/components/ProductItem/ProductImage';
import mapLegacyStore from 'src/helpers/migrationRedirect';
import LogoBacu from 'src/components/icons/Logo';
import ProductHeader from 'src/components/Product/components/ProductHeader';
import ProductDescription from 'src/components/Product/components/ProductDescription';
import ProductPrice from 'src/components/Product/components/ProductPrice';
import LocationIcon from 'src/components/icons/LocationIcon';
import CartButton from 'src/components/CartButton';
import { useSelector } from 'react-redux';
import useDelivery from 'src/hooks/actions/useDelivery';
import useScrollHandler from 'src/hooks/useScrollHandler';
import FormInputPanel from '../components/FormInputPanel';
import ModifierGroup from '../components/ModifierGroup';
import Skeleton from '../components/Skeleton';
import { useShoppingCart } from '../hooks/actions/useShoppingCart';
import { useStore } from '../hooks/actions/useStore';
import { useProductDetail } from '../hooks/services/useProductDetail';
import { useAmplitude } from '../hooks/useAmplitude';
import {
  addDisablesToModifiers, getProductPrice, hasMinOptionsRequired, mixProductWithForm,
} from '../utils/product';
import AddToCartButton from './components/AddToCartButton';

import '../styles/Product/index.css';

function ProductDetailSkeleton() {
  return (
    <div className="flex flex-col p-4 col-span-4 lg:p-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-auto">
        <Skeleton className="max-w-[500px] w-full aspect-square lg:ml-auto" />

        <div className="space-y-4 h-full max-w-[500px] w-full lg:mr-auto">
          <Skeleton className="h-16" />
          <Skeleton className="h-8" />

          <div className="space-y-8 !mt-16">
            <Skeleton className="h-12 w-1/2" />
            <Skeleton className="h-8 w-2/3" />
          </div>

          <div className="space-y-4">
            <Skeleton className="h-40" />
          </div>

          <Skeleton className="h-12" />
        </div>

      </div>
    </div>
  );
}

function ProductDetail() {
  useScrollHandler();
  const { selectedStore } = useStore();
  const { addProduct } = useShoppingCart();
  const [addedProduct, setAddedProduct] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      modifierGroups: [],
    },
  });
  const modifierGroupsForm = useWatch({
    control: methods.control,
    name: 'modifierGroups',
  });
  const { storeID: legacyID, productID } = useParams();
  const storeID = mapLegacyStore(legacyID);
  const { isLoading, productDetail } = useProductDetail({ storeID, productID });
  const modifierGroupsData = JSON.stringify(modifierGroupsForm);
  useEffect(() => {
    if (addedProduct) {
      setAddedProduct(false);
    }
  }, [modifierGroupsData]);

  const { trackAddItemToCart } = useAmplitude().useCartPageEvents();

  const handleBack = () => navigate(-1);

  const handleCart = () => navigate(`/store/${legacyID}/cart`);

  const { items = [] } = useSelector((state) => state.cart);
  const total = items.reduce((sum, item) => sum + item.count, 0);

  const { deliveryData } = useDelivery();

  const { isDelivery, address } = deliveryData;

  const handleAddProduct = () => {
    trackAddItemToCart(productID);
    handleBack();
  };

  // Obtener el precio total del producto
  const calculatedModifierGroups = mixProductWithForm(productDetail, { modifierGroups: modifierGroupsForm });
  productDetail.total = getProductPrice(calculatedModifierGroups, productDetail);

  // Obtener los requisitos minimos para pedir
  productDetail.modifierGroups = addDisablesToModifiers(productDetail, modifierGroupsForm);
  const minOptionsRequired = hasMinOptionsRequired(productDetail);

  const onSubmit = (data) => {
    const modifierGroups = mixProductWithForm(productDetail, data);

    if (!addedProduct) {
      setAddedProduct(true);
      addProduct({
        ...productDetail,
        imageUrl: productDetail.shortcutImage,
        modifierGroups,
        observations: data.observations,
      });
    }
  };

  const textareaId = `observations-${productDetail.id}`;

  return (
    <MotionConfig transition={{ duration: 0.25 }}>
      <motion.main
        className="product__wrapper"
        initial={{ left: '100%' }}
        animate={{ left: 0 }}
        exit={{ left: '100%' }}
      >
        <header className="header">
          <button
            type="button"
            onClick={handleBack}
            className="header__back-button"
            title={`Volver a ${selectedStore?.name}`}
            aria-label={`Volver a ${selectedStore?.name}`}
          >
            <ArrowBack alt="bacu-arrow-left" />
          </button>

          <button
            type="button"
            onClick={() => navigate('/')}
            className="header__logo-button"
            title="Ir a la página principal"
            aria-label="Ir a la página principal"
          >
            <LogoBacu className="header__logo" />
          </button>

          <div className="header__actions">
            <button
              className="header__location-store"
              onClick={isDelivery ? () => navigate('/MyAddress') : handleBack}
              type="button"
            >
              <LocationIcon />
              <span className="header__location-text">{isDelivery ? address : selectedStore?.name}</span>
            </button>

            <CartButton total={total} onGoToCart={handleCart} />
          </div>
        </header>

        {isLoading ? (
          <ProductDetailSkeleton />
        ) : (
          <section className="product__content">
            <ProductImage imageUrl={productDetail.shortcutImage} name={productDetail.name} />
            <div className="product__info">
              <div className="product__details">
                <ProductHeader
                  isTitle
                  name={productDetail.name}
                  tags={productDetail.tags}
                  discountedPrice={productDetail.discountedPrice}
                  price={productDetail.price}
                />
                <ProductDescription description={productDetail.description} />

                <ProductPrice
                  price={productDetail.price}
                  discountedPrice={productDetail.discountedPrice}
                  tags={productDetail.tags}
                />
              </div>
            </div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} className="product__form" id="product-detail-form">
                {!productDetail.isAvailable && <div className="">Producto No Disponible</div>}

                {productDetail.modifierGroups?.map((modifierGroup, index) => (
                  <ModifierGroup
                    className=""
                    index={index}
                    key={modifierGroup.id}
                    name={modifierGroup.name}
                    modifiers={modifierGroup.modifiers}
                    selectType={modifierGroup.selectType}
                    minAllowed={modifierGroup.minAllowed}
                    maxAllowed={modifierGroup.maxAllowed}
                    disables={modifierGroup.disables}
                    disabled={!productDetail.isAvailable}
                  />
                ))}
                <FormInputPanel
                  id="observations"
                  panelElements={[
                    {
                      label: (
                        <Fragment key={productDetail.id}>
                          <p>¿Algún comentario?</p>
                          <span>Déjanos saber algún otro requerimiento</span>
                        </Fragment>
                      ),
                      id: textareaId,
                      type: 'textarea',
                      name: 'observations',
                      placeholder: 'Ej. El picante por separado...',
                      rows: 3,
                      resizable: false,
                      fullWidth: true,
                      classes: ['product__observations'],
                    },
                  ]}
                />
              </form>
            </FormProvider>
          </section>
        )}
        <div className="product__footer" hidden={isLoading}>
          <AddToCartButton
            form="product-detail-form"
            product={productDetail}
            minRequirements={minOptionsRequired}
            startAnimation={addedProduct}
            onAnimationEnd={handleAddProduct}
          />
        </div>
        <div className="product__footer__img" hidden={isLoading}>
          <ProductImage imageUrl={productDetail.shortcutImage} name={productDetail.name} />
          <AddToCartButton
            form="product-detail-form"
            product={productDetail}
            minRequirements={minOptionsRequired}
            startAnimation={addedProduct}
            onAnimationEnd={handleAddProduct}
          />
        </div>
      </motion.main>
    </MotionConfig>
  );
}

ProductDetail.propTypes = {};

export default ProductDetail;
