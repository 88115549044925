import PropTypes from 'prop-types';
import CartIconEmpty from './icons/CartIconEmpty';
import CartIconFill from './icons/CartIconFill';

function CartButton({ total, onGoToCart }) {
  return (
    <div className="header__cart__button">
      <button className="button__cart" onClick={onGoToCart} type="button">
        {total > 0 ? (
          <>
            <span className="cart__total">{total}</span>
            <CartIconFill className="cart__icon" />
          </>
        ) : (
          <CartIconEmpty className="cart__icon" />
        )}
      </button>
    </div>
  );
}

export default CartButton;

CartButton.propTypes = {
  total: PropTypes.number,
  onGoToCart: PropTypes.func,
};

CartButton.defaultProps = {
  total: 0,
  onGoToCart: () => {},
};
