import PropTypes from 'prop-types';

function ItemsGroup(props) {
  const {
    id,
    title,
    children,
    showImg,
  } = props;

  return (
    <section className="wrapper">
      <h2 className="category__subtitle" id={id}>
        {title}
      </h2>
      <article className="product__layout" data-show-img={showImg} data-group-id={id}>
        {children}
      </article>
    </section>
  );
}

ItemsGroup.propTypes = {
  id: PropTypes.string,
  title: PropTypes.node.isRequired,
  showImg: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
ItemsGroup.defaultProps = {
  id: null,
};

export default ItemsGroup;
