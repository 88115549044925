/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { register } from '../../services/api';
import { useSession } from '../actions/useSession';
import { useAmplitude } from '../useAmplitude';

export function useSignUp() {
  const {
    changeSessionToken,
    changeRefreshToken,
    token,
    redirectUrl,
    cart_session,
  } = useSession();
  const navigate = useNavigate();
  const { trackSignUpCreateAccountSuccess } = useAmplitude().useLoginPageEvents();
  const {
    mutate: signUp,
    isLoading,
    error,
  } = useMutation(({
    email,
    password,
    last_name,
    first_name,
    phone,
  }) => register({
    email, password, last_name, first_name, phone, cart_session: cart_session ?? '',
  }), {
    onSuccess: (res) => {
      trackSignUpCreateAccountSuccess();
      changeRefreshToken(res.data.refresh_token);
      changeSessionToken(res.data.token);

      navigate(redirectUrl ?? '/');
    },
  });

  return {
    isLoading, token, signUp, error,
  };
}

export default useSignUp;
