import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { formatCurrency } from '../../utils/currency';
import FormInputPanel from '../FormInputPanel';

const MODIFIER_CONFIG = {
  checkBoxes: {
    type: 'checkbox',
    class: 'modifier__checkbox',
  },
  counters: {
    type: 'counter',
    class: 'modifier__counter',
  },
  radioBtn: {
    type: 'radio',
    class: 'modifier__radio',
  },
};

function getModifierConfig({ selectType }) {
  return MODIFIER_CONFIG[selectType] || MODIFIER_CONFIG.counters;
}

function ModifierItem({
  name,
  inputName,
  disabled,
  minAllowed,
  maxAllowed,
  maxDisabled,
  isAvailable,
  price,
  selectType,
  className,
  id,
  ...args
}) {
  const inputId = `${inputName}-${id}`;
  const { type, class: modifierClass } = getModifierConfig({ selectType });

  return (
    <FormInputPanel
      id={inputName}
      panelElements={[
        {
          ...args,
          label: (
            <Fragment key={inputId}>
              <p>{name}</p>
              <span>{formatCurrency(price)}</span>
            </Fragment>
          ),
          id: inputId,
          htmlFor: inputId,
          name: inputName,
          type,
          fullWidth: true,
          disabled: !isAvailable || disabled,
          min: minAllowed,
          max: maxAllowed,
          allowMinValue: true,
          maxDisabled,
          direction: 'row',
          classes: [modifierClass, className],
        },
      ]}
    />
  );
}

ModifierItem.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  minAllowed: PropTypes.number,
  maxAllowed: PropTypes.number,
  price: PropTypes.number,
  selectType: PropTypes.oneOf(['checkBoxes', 'counters', 'radioBtn']),
  isAvailable: PropTypes.bool,
  maxDisabled: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};
ModifierItem.defaultProps = {
  disabled: false,
  price: null,
  minAllowed: null,
  maxAllowed: null,
  selectType: 'counters',
  isAvailable: true,
  maxDisabled: false,
  className: '',
};

export default ModifierItem;
