import PropTypes from 'prop-types';
import ProductCard from '../Product/ProductCard';
import Skeleton from '../Skeleton';

function ProductItem({
  isLoading,
  imageUrl,
  id,
  name,
  description,
  price,
  discountedPrice,
  onCart,
  tags,
  isAvailable,
  onClick,
}) {
  const handleClick = () => onClick(id);

  if (isLoading) {
    return (
      <div className="product" data-loading={isLoading}>
        <div className="product__picture">
          <Skeleton type="rect" className="h-full rounded-none" />
        </div>
        <div className="product__header">
          <Skeleton className="h-6 w-8/12" />
        </div>
        <div className="product__info">
          <Skeleton className="h-8 w-full" />
          <Skeleton className="h-5 w-4/12 mt-2" />
        </div>
      </div>
    );
  }

  return (
    <ProductCard
      imageUrl={imageUrl}
      name={name}
      description={description}
      price={price}
      discountedPrice={discountedPrice}
      tags={tags}
      onCart={onCart}
      isAvailable={isAvailable}
      handleClick={handleClick}
    />
  );
}

ProductItem.propTypes = {
  isLoading: PropTypes.bool,
  imageUrl: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  discountedPrice: PropTypes.number,
  isAvailable: PropTypes.bool,
  onCart: PropTypes.number,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      bgColor: PropTypes.string,
      fontColor: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
};
ProductItem.defaultProps = {
  isLoading: false,
  imageUrl: null,
  name: '',
  description: '',
  discountedPrice: null,
  isAvailable: false,
  onCart: 0,
  price: null,
  tags: [],
  onClick: () => null,
};

export default ProductItem;
