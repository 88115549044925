import PropTypes from 'prop-types';
import ProductBadge from './components/ProductBadge';
import ProductDescription from './components/ProductDescription';
import ProductHeader from './components/ProductHeader';
import ProductImage from './components/ProductImage';
import ProductPrice from './components/ProductPrice';

function ProductCard({
  imageUrl, name, description, price, discountedPrice, tags, onCart, isAvailable, handleClick,
}) {
  return (
    <button
      type="button"
      className="product"
      aria-disabled={!isAvailable}
      tabIndex={!isAvailable ? -1 : 0}
      onClick={(e) => {
        if (!isAvailable) {
          e.preventDefault();
          return;
        }

        handleClick();
      }}
      data-show-img={!!imageUrl}
      data-is-available={isAvailable}
    >
      <ProductImage imageUrl={imageUrl} name={name} />
      <ProductBadge isAvailable={isAvailable} />
      {onCart > 0 && (
        <div className="product__cart" data-on-cart>
          <span className="product__cart--quantity">{onCart}</span>
        </div>
      )}
      <ProductHeader name={name} tags={tags} discountedPrice={discountedPrice} price={price} />
      <footer className="product__info">
        <ProductDescription description={description} />

        <ProductPrice price={price} discountedPrice={discountedPrice} />
      </footer>
    </button>
  );
}

export default ProductCard;

ProductCard.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  discountedPrice: PropTypes.number,
  tags: PropTypes.array,
  onCart: PropTypes.number,
  isAvailable: PropTypes.bool,
  handleClick: PropTypes.func,
};

ProductCard.defaultProps = {
  imageUrl: '',
  name: '',
  description: '',
  price: 0,
  discountedPrice: 0,
  tags: [],
  onCart: 0,
  isAvailable: true,
  handleClick: () => {},
};
