import PropTypes from 'prop-types';

function ProductTags({ discountedPrice, price, tags }) {
  if (discountedPrice && discountedPrice < price) {
    return (
      <div className="product__tags">
        {tags.map(({ label }) => (
          <span className="" key={label}>
            {label}
          </span>
        ))}
      </div>
    );
  }
  return null;
}
export default ProductTags;

ProductTags.defaultProps = {
  discountedPrice: 0,
  price: 0,
  tags: [],
};

ProductTags.propTypes = {
  discountedPrice: PropTypes.number,
  price: PropTypes.number,
  tags: PropTypes.array,
};
