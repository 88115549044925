import PropTypes from 'prop-types';

function MinusIcon({ size, className }) {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.6" y="0.6" width="22.8" height="22.8" rx="3.4" stroke="currentColor" strokeWidth="1.2" />
      <path d="M5 12H19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default MinusIcon;

MinusIcon.defaultProps = {
  size: 24,
  className: '',
};

MinusIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};
