import PropTypes from 'prop-types';

function ContainerWithMenu(props) {
  const {
    children,
  } = props;

  return (

    <main
      className="main__container"
    >
      {children}
    </main>

  );
}

ContainerWithMenu.propTypes = {
  children: PropTypes.node,
};
ContainerWithMenu.defaultProps = {
  children: null,
};

export default ContainerWithMenu;
